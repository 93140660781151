import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GroupOrderInviteModal from '@artemis/components/GroupOrder/GroupOrderInviteModal';
import {
  setInviteModal,
  updateFulfillment,
} from '@artemis/store/groupOrder/slice';
import {
  getFulfillmentId,
  getFulfillmentShareMode,
  getIsInviteModalOpen,
  getIsUpdateFulfillmentLoading,
  getJoinUri,
  getSupportedShareModes,
  getIsFulfillmentLoaded,
} from '@artemis/store/groupOrder/selectors';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import { SHARE_MODE } from './constants';

const GroupOrderInviteModalContainer = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(getIsInviteModalOpen);
  const joinUri = useSelector(getJoinUri);
  const fulfillmentId = useSelector(getFulfillmentId);
  const merchantId = useSelector(getMerchantId);
  const isLoading = useSelector(getIsUpdateFulfillmentLoading);
  const isFulfillmentLoaded = useSelector(getIsFulfillmentLoaded);
  const shareMode = useSelector(getFulfillmentShareMode);
  const supportedShareModes = useSelector(getSupportedShareModes);

  const showShareToggle = supportedShareModes.includes(SHARE_MODE.DEFAULT_TEAM);

  const onClose = () => {
    dispatch(setInviteModal({ isOpen: false }));
  };

  const onSave = newShareMode => {
    dispatch(
      updateFulfillment({
        fulfillmentId,
        fulfillment: {
          shareSettings: {
            shareMode: newShareMode,
          },
        },
      }),
    );
  };

  if (!isFulfillmentLoaded) {
    return null;
  }

  return (
    <GroupOrderInviteModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      joinUrl={joinUri}
      fulfillmentId={fulfillmentId}
      merchantId={merchantId}
      showShareToggle={showShareToggle}
      isLoading={isLoading}
      shareMode={shareMode}
    />
  );
};

export default GroupOrderInviteModalContainer;
