import { useState } from 'react';
import useClipboard from 'react-use-clipboard';

export const STATUS = {
  SHARED: 'SHARED',
  COPIED: 'COPIED',
};

const useNativeShare = () => {
  const [_, setFallbackCopied] = useClipboard(''); // eslint-disable-line no-unused-vars
  const [status, setStatus] = useState(null);

  const resetStatusInOneSec = () => {
    setTimeout(() => {
      setStatus(null);
    }, 1000);
  };

  const copyToClipBoard = async data => {
    try {
      await navigator.clipboard.writeText(data.url);
    } catch (err) {
      // Fall back to react-use-clipboard, which uses execCommand (deprecated)
      // to handle copy to clip board
      setFallbackCopied(data.url);
    } finally {
      setStatus(STATUS.COPIED);
      resetStatusInOneSec();
    }
  };

  const share = async ({ shareData }) => {
    if ('share' in navigator) {
      try {
        await navigator.share(shareData);
        setStatus(STATUS.SHARED);
        resetStatusInOneSec();
      } catch (err) {
        // If share fails, not because user aborted
        if (err.name !== 'AbortError') {
          copyToClipBoard(shareData);
        }
      }
    } else {
      copyToClipBoard(shareData);
    }
  };

  return [share, status];
};

export default useNativeShare;
