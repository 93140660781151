import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RtToggle } from '@ritualco/ritual-frontend-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';

const Container = styled.div`
  display: flex;
  margin-top: 28px;
`;

const Content = styled.div`
  text-align: left;
  margin-left: 8px;
`;

const Title = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.rtColors.black1000};
`;

const Details = styled.p`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.black1000};
`;

const TeamShareToggle = ({ onToggle, enabled }) => (
  <Container>
    <RtToggle isActive={enabled} onClick={() => onToggle(!enabled)} />
    <Content>
      <Title>
        <FormattedMessage entry="groupOrder.invite.teamShare.title" />
      </Title>
      <Details>
        <FormattedMessage entry="groupOrder.invite.teamShare.details" />
      </Details>
    </Content>
  </Container>
);

TeamShareToggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default TeamShareToggle;
