import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useNativeShare, { STATUS } from '@artemis/utils/useNativeShare';
import { addToast } from '@artemis/store/toasts/slice';

// Shares/copies a group order invite link and shows a confirmation toast
const useShareGroupOrder = ({ joinUrl }) => {
  const dispatch = useDispatch();
  const [share, shareStatus] = useNativeShare();

  useEffect(() => {
    if (shareStatus === STATUS.COPIED) {
      dispatch(
        addToast({
          toast: { message: 'groupOrder.invite.linkCopied' },
        }),
      );
    } else if (shareStatus === STATUS.SHARED) {
      dispatch(
        addToast({
          toast: { message: 'groupOrder.invite.linkShared' },
        }),
      );
    }
  }, [shareStatus]);

  return () =>
    share({
      shareData: {
        url: joinUrl,
      },
    });
};

export default useShareGroupOrder;
