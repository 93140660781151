import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';
import { RtTextInput } from '@ritualco/ritual-frontend-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import StandardModal from '@artemis/components/StandardModal';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { logGroupOrderCopyShareLinkClick } from '@artemis/containers/GroupOrder/analytics';
import { SHARE_MODE } from '@artemis/containers/GroupOrder/constants';
import useShareGroupOrder from './useShareGroupOrder';
import TeamShareToggle from './TeamShareToggle';

const Title = styled.h2`
  ${props => props.theme.typography.h3};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  text-align: center;
  margin-bottom: 48px;
`;

const Box = styled.div`
  color: ${props => props.theme.rtColors.black700};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  text-align: center;
  ${props => props.theme.isTablet`
    margin-bottom: 230px;
  `};
`;

const ShareContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

const StyledInput = styled(RtTextInput)`
  width: 100%;
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: ${props => props.theme.rtColors.black100};
  color: ${props => props.theme.rtColors.black1000};
  ${props => props.theme.typography.button};
  padding: 10px 20px;
  border-radius: 4pt;
  border: none;
  cursor: pointer;
  transition: 0.25s background-color ease;
  :hover {
    background-color: ${props =>
      !props.disabled &&
      props.theme.palette.hover.onLightBg(props.theme.rtColors.black100)};
  }
`;

const CopyButton = styled(StyledButton)`
  width: initial;
  background: none;
  color: ${props => props.theme.palette.primary};
  border: 1px solid ${props => props.theme.palette.primary};
  text-transform: uppercase;
  margin-left: 10px;
  :hover {
    background-color: ${props => lighten(0.51, props.theme.palette.primary)};
  }
`;

const GroupOrderInviteModalProps = {
  joinUrl: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  fulfillmentId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showShareToggle: PropTypes.bool,
  isLoading: PropTypes.bool,
  shareMode: PropTypes.oneOf(Object.values(SHARE_MODE)),
};

const GroupOrderInviteModal = ({
  joinUrl,
  merchantId,
  fulfillmentId,
  isOpen,
  onClose,
  onSave,
  showShareToggle = false,
  isLoading,
  shareMode,
}) => {
  const share = useShareGroupOrder({ joinUrl });

  const onCopyClick = () => {
    logGroupOrderCopyShareLinkClick({ merchantId, fulfillmentId });
    share();
  };

  const onPrimaryButtonClick = () => {
    onClose();
  };

  const onToggleTeam = enabled => {
    if (showShareToggle) {
      onSave(enabled ? SHARE_MODE.DEFAULT_TEAM : SHARE_MODE.LINK_ONLY);
    }
  };

  return (
    <StandardModal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      onRequestClose={onClose}
      primaryButtonText={<FormattedMessage entry="global.done" />}
      onClickPrimaryButton={onPrimaryButtonClick}
      isLoading={isLoading}
    >
      <Title>
        <FormattedMessage entry="groupOrder.invite.title" />
      </Title>
      <Box>
        <ResponsiveImage id="groupOrder.invite.img" />
        <ShareContainer>
          <StyledInput value={joinUrl} readOnly />
          <CopyButton onClick={onCopyClick}>
            <FormattedMessage entry="global.copy" />
          </CopyButton>
        </ShareContainer>
        {showShareToggle && (
          <TeamShareToggle
            onToggle={onToggleTeam}
            enabled={shareMode === SHARE_MODE.DEFAULT_TEAM}
          />
        )}
      </Box>
    </StandardModal>
  );
};

GroupOrderInviteModal.propTypes = GroupOrderInviteModalProps;
export default GroupOrderInviteModal;
